import Cookies from "js-cookie";
import React, { Suspense, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { CssBaseline, LinearProgress, StyledEngineProvider } from "@mui/material";

import { AxiosLoader } from "./components";
import { LayoutProvider, LoaderContextProvider, ThemeProvider } from "./contexts";
import { useAppInsightsContext, useLayout } from "./hooks";
import AddToHomeScreenDialog from "./runtime/AddToHomeScreenDialog";
import { AuthenticationContextProvider } from "./runtime/AuthenticationContext";
import Clarity from "./runtime/Clarity";
import ErrorBoundary from "./runtime/ErrorBoundary";
import Manifest from "./runtime/Manifest";
import { Layouts } from "./views/layouts";

const ResourceLoader = React.lazy(() => import("./runtime/ResourceLoader"));
const GoogleTagManagerProvider = React.lazy(() => import("./runtime/GoogleTagManagerProvider"));

function RemoveTrailingSlash({ ...props }) {
  const { pathname, search } = useLocation();

  if (pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...props}
        to={{
          pathname: pathname.replace(/\/+$/, ""),
          search: search,
        }}
      />
    );
  } else return null;
}

function TenantApp({ ...props }) {
  const layout = useLayout();
  const [searchParams] = useSearchParams();
  const source = searchParams.get("neo.src");
  const appInsights = useAppInsightsContext();

  const TagName = Layouts[layout.type];
  if (!TagName) {
    throw new Error(`Layout '${layout.type}' not found`);
  }

  const pageName = `${layout.tenant.name}/${layout.type}/${layout.name}`;
  useEffect(() => {
    const properties: { [key: string]: any } = { layoutType: layout.type };
    if (source) {
      properties.src = source;
    }
    appInsights.trackPageView({
      name: pageName,
      uri: document.location.href,
      pageType: layout.type,
      properties: properties,
    });
  }, [appInsights, pageName, layout.type, source]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LoaderContextProvider>
          <Suspense fallback={<LinearProgress />}>
            <AxiosLoader />
            <CssBaseline />
            <Manifest />
            <Clarity />
            {layout.google?.gtmId && <GoogleTagManagerProvider gtmId={layout.google.gtmId} />}
            <AddToHomeScreenDialog />
            <TagName {...props} />
          </Suspense>
        </LoaderContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default function App() {
  const params = useParams() as { tenantId: string; layoutName: string } & Record<string, string>;
  params.tenantId = (Cookies.get("tenantId") || params.tenantId).toLowerCase();
  params.layoutName = params.layoutName.toLowerCase();

  const { tenantId, layoutName, ...props } = params;

  return (
    <ErrorBoundary>
      <RemoveTrailingSlash />
      <LayoutProvider tenantId={tenantId} layoutName={layoutName}>
        {/*  this errorBoundary will have access to layout context and may use custom error layout */}
        <ErrorBoundary listenForUnhandledRejection>
          <AuthenticationContextProvider>
            <ResourceLoader>
              <TenantApp {...props} />
            </ResourceLoader>
          </AuthenticationContextProvider>
        </ErrorBoundary>
      </LayoutProvider>
    </ErrorBoundary>
  );
}
