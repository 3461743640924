import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { shouldPolyfill } from "@formatjs/intl-displaynames/should-polyfill";

import { getResource } from "../locales/getResource";

export default function IntlLoader({ children }: { children: ReactNode }) {
  const [intlLoaded, setIntlLoaded] = useState(false);
  const { i18n } = useTranslation("common.behaviors");
  const locale = i18n.language;

  useEffect(() => {
    if (shouldPolyfill(locale) !== true) {
      setIntlLoaded(true);
    } else {
      (async () => {
        await import("@formatjs/intl-displaynames/polyfill");
        await getResource("intl-displaynames", locale);
        setIntlLoaded(true);
      })();
    }
  }, [locale]);

  return <>{intlLoaded && children}</>;
}
