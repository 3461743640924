import { useSearchParams } from "react-router-dom";

export default function useIsDebug() {
  const [urlSearchParams] = useSearchParams();

  return {
    get isDebug() {
      const debug = urlSearchParams.get("neo.debug");

      return debug !== null && (debug === "true" || debug === "1");
    },
  };
}
