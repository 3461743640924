import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Snackbar } from "@mui/material";

export default function ServiceWorkerUpdater() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const onMessage = (e: MessageEvent<{ type: string }>) => {
      if (e.data?.type === "UPDATE_AVAILABLE") {
        setOpen(true);
      } else if (e.data?.type === "RESTART") {
        window.location.reload();
      }
    };
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  const handleClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleRestart = () => {
    window.postMessage({ type: "START_UPDATE" }, "*");
  };

  return (
    <>
      <Snackbar
        open={open}
        message="An update is available. Please restart the application !"
        onClose={handleClose}
        action={
          <>
            <Button color="secondary" size="small" onClick={handleRestart}>
              Update
            </Button>
            <IconButton aria-label="close" color="inherit" sx={{ padding: 0.5 }} onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </>
  );
}
