import React from "react";

const Layouts = {
  customerRegistration: React.lazy(() => import("./CustomerRegistration")),
  pass: React.lazy(() => import("./Pass")),
  passList: React.lazy(() => import("./PassList")),
  ticketRegistration: React.lazy(() => import("./TicketRegistration")),
  receiptDisplayer: React.lazy(() => import("./ReceiptDisplayer")),
  error: React.lazy(() => import("./Error")),
  linkDisplayer: React.lazy(() => import("./LinkDisplayer")),
  vendor: React.lazy(() => import("./Vendor")),
};

export { Layouts };
