import { useTranslation } from "react-i18next";

export default function RedirectNotFound() {
  const { t } = useTranslation("error.redirectNotFound");
  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", textAlign: "center" }}
    >
      <div>
        <h1>{t("title")}</h1>
        <p>{t("content")}</p>
      </div>
    </div>
  );
}
