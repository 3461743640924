import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { useTranslation } from "../hooks";

// FROM  https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 *
 * @deprecated Only supported on Chrome and Android Webview.
 */
interface BeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}
declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export default function AddToHomeScreenDialog() {
  const [open, setOpen] = useState(false);
  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent | null>(null);
  const { t } = useTranslation("common.addToHomeScreenDialog");
  const [urlSearchParams] = useSearchParams();

  const isPwaInstall = !!urlSearchParams.get("neo.pwaInstall");

  useEffect(() => {
    const ready = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      if (isPwaInstall) {
        setOpen(true);
        setPromptEvent(e);
      }
    };

    window.addEventListener("beforeinstallprompt", ready, { once: true });

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, [isPwaInstall]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleInstall = async () => {
    setOpen(false);
    if (promptEvent) {
      promptEvent.prompt();
      promptEvent.userChoice.then(() => {
        setPromptEvent(null);
      });
    }
  };

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{t("title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t("description")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("cancel")}
        </Button>
        <Button onClick={handleInstall} color="primary" autoFocus>
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
