import { isFunction } from "lodash";
import { useCallback } from "react";

import { useLayout } from "./";

export default function useLayoutFieldModifier() {
  const { form } = useLayout();

  const fields = [...(form.fields || []), ...(form.steps?.flatMap((s) => s.fields || []) || [])];

  function fieldsModifier(
    name: string,
    option: {
      readonly?: boolean;
    }
  ): void;
  function fieldsModifier(name: string, modifier: (field: any) => void): void;

  function fieldsModifier(name: string, modifierOrOption: ((field: any) => void) | { readonly?: boolean }) {
    let modifier: (field: any) => void;
    if (isFunction(modifierOrOption)) {
      modifier = modifierOrOption;
    } else {
      modifier = (f) => {
        if (modifierOrOption.readonly !== undefined) {
          f.inputProps = { ...f.inputProps, readOnly: modifierOrOption.readonly, disabled: modifierOrOption.readonly };
        }
      };
    }

    fields.filter((f) => f.name === name).forEach(modifier);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fieldsModifierCallback = useCallback(fieldsModifier, [...fields]);

  return { modifier: fieldsModifierCallback };
}
