import { useLayout } from "./";

export default function useDocument(documentUrl: string | null) {
  const layout = useLayout();

  if (!documentUrl) {
    return null;
  }

  const document = layout.documents?.[documentUrl];
  if (!document) {
    return { url: documentUrl };
  } else {
    return document;
  }
}
