import axios from "axios";
import React, { Component, ErrorInfo, ReactNode, useCallback } from "react";
import { useLocation } from "react-router";

import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { AppInsightsContext, LayoutContext } from "../contexts";
import { useAppInsightsContext, useIsDebug, useLayout } from "../hooks";

const ErrorInfoComponent = React.lazy(() => import("./ErrorInfoComponent"));

interface Props {
  appInsights: ApplicationInsights;
  listenForUnhandledRejection?: boolean;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

function logError(appInsights: ApplicationInsights, error: Error | any, exceptionProperties: Record<string, any>) {
  if (typeof error === "object") {
    if (error.isLogged) {
      console.error("error has already been logged", error);
      return;
    }
    error.isLogged = true;
  }

  if (!(error instanceof Error)) {
    let message = error?.message;
    try {
      message = JSON.stringify(error, null, 2);
    } catch (ex) {
      try {
        message = error.toString();
      } catch {
        message = "can't get information from error";
      }
    }
    const newError = new Error(message);
    Object.assign(newError, error);
    error = newError;
    error.isLogged = true;
  }

  if (axios.isAxiosError(error)) {
    exceptionProperties.axios = { request: error.request, response: error.response };
  }

  exceptionProperties.fromErrorBoundary = true;

  appInsights.trackException({
    error: error,
    exception: error,
    severityLevel: SeverityLevel.Error,
    properties: exceptionProperties,
  });
}

function useLogError() {
  const appInsights = useAppInsightsContext();

  const logErrorInternal = useCallback(
    (error: Error | any, exceptionProperties: Record<string, any>) => {
      return logError(appInsights, error, exceptionProperties);
    },
    [appInsights]
  );

  return { logError: logErrorInternal };
}

function ErrorDisplayer({ error, onClose }: { error: Error; onClose: () => void }) {
  const { isDebug } = useIsDebug();
  const location = useLocation();
  const { logError } = useLogError();

  const {
    tenant: { name: tenantId },
    errorLayoutName,
  } = useLayout();

  if (!isDebug && typeof tenantId === "string" && typeof errorLayoutName === "string") {
    // avoid infinite redirect loop if an error occured on the custom error layout
    if (location.pathname === `/${tenantId}/${errorLayoutName}`) {
      const newError = new Error(`An error occured with custom error layout`);
      logError(newError, {
        errorWithCustomErrorLayout: true,
        originalErrorMessage: error?.message,
      });
      return (
        <>
          <h1>A fatal exception has occured</h1>
          <h2>{error?.name}</h2>
          <code>{error?.message}</code>
        </>
      );
    } else {
      document.location = `/${tenantId}/${errorLayoutName}`;
      return <></>;
    }
  } else {
    return <ErrorInfoComponent error={error} onClose={onClose} />;
  }
}

class ErrorBoundary extends Component<Props, State> {
  static contextType = LayoutContext;
  context!: React.ContextType<typeof LayoutContext>;

  static getDerivedStateFromError(error: Error | null) {
    // a ChunkLoadError may happens if the user get a old version of index.html - from cache or because a new version has been published
    // let's force a reload of the page which with a unique querystring parameter
    if (error?.name === "ChunkLoadError" || (error?.message && error.message.startsWith("ChunkLoadError"))) {
      const params = new URLSearchParams(document.location.search);
      params.set("neo.reload", Date.now().toString());

      document.location.search = params.toString();
      document.location.reload();
    }

    if (error) {
      return { hasError: true, error };
    } else {
      return { hasError: false, error: null };
    }
  }

  public state: State = {
    hasError: false,
    error: null,
  };

  componentDidMount() {
    this.listenForError();
  }

  componentWillUnmount() {
    if (this.props.listenForUnhandledRejection) {
      window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
    }
  }

  onUnhandledRejection = (event: PromiseRejectionEvent) => {
    // avoid firing in a loop in case of error in error handling
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);

    event.promise.catch((error: Error) => {
      this.setState(ErrorBoundary.getDerivedStateFromError(error));
      this.logError(error, {
        unhandleRejection: true,
      });
    });
  };

  componentDidCatch(error: Error | any, errorInfo: ErrorInfo) {
    this.logError(error, errorInfo || {});
  }

  logError(error: Error | any, exceptionProperties: Record<string, any>) {
    logError(this.props.appInsights, error, exceptionProperties);
  }

  listenForError() {
    if (this.props.listenForUnhandledRejection) {
      window.addEventListener("unhandledrejection", this.onUnhandledRejection);
    }
  }

  render() {
    const { hasError, error } = this.state;

    return (
      <>
        {hasError && error != null && (
          <ErrorDisplayer
            error={error}
            onClose={() => {
              this.listenForError();
            }}
          />
        )}
        {this.props.children}
      </>
    );
  }
}

const withAppInsightsContext =
  <P extends Props>(Component: React.ComponentType<P>) =>
  (props: any) =>
    (
      <AppInsightsContext.Consumer>
        {(context) => <Component appInsights={context} {...props} />}
      </AppInsightsContext.Consumer>
    );
export default withAppInsightsContext(ErrorBoundary);
