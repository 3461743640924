import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ReactNode, useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";

import { SeverityLevel } from "@microsoft/applicationinsights-common";

import { useOptionalLayout } from "../contexts/LayoutProvider";
import { useAppInsightsContext } from "../hooks";
import { StaticBackend } from "../locales/StaticBackend";

export default function ResourceLoader({ children }: { children: ReactNode | undefined }) {
  const layout = useOptionalLayout();
  const [isInit, setIsInit] = useState(false);
  const appInsights = useAppInsightsContext();
  const { defaultLanguage = "en", availableLanguages = ["en"], resources = {} } = layout?.internationalization || {};

  useEffect(() => {
    const supportedLanguages = availableLanguages.flatMap((lng) => {
      try {
        if (!lng || typeof lng !== "string") {
          return [lng];
        }
        const i = lng.indexOf("-");
        if (i > 0) {
          return [lng, lng.slice(0, i)];
        } else {
          return [lng];
        }
      } catch {
        return [lng];
      }
    });
    (async () => {
      i18n
        .use(StaticBackend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["p", "br", "strong", "em"],
          },
          backend: {
            layoutResources: resources,
          },
          detection: {
            lookupCookie: "neo.lng",
            lookupQuerystring: "neo.lng",
            caches: ["cookie"],
          },
          fallbackLng: (lng) => {
            if (!lng || typeof lng !== "string") {
              return [defaultLanguage];
            }
            try {
              const i = lng.indexOf("-");
              if (i > 0) {
                return [lng.slice(0, i), defaultLanguage];
              } else {
                return [defaultLanguage];
              }
            } catch {
              return [defaultLanguage];
            }
          },
          saveMissing: true,
          missingKeyHandler: (
            lngs: readonly string[],
            ns: string,
            key: string,
            fallback: string,
            updateMissing: boolean,
            options: any
          ) => {
            if (typeof options?.defaultValue !== "undefined") {
              return;
            }
            appInsights.trackTrace({
              message: `translation is missing for language ${lngs}, namespace ${ns}, key ${key}`,
              severityLevel: SeverityLevel.Warning,
              properties: {
                lng: Array.isArray(lngs) ? lngs.join(".") : lngs,
                ns: Array.isArray(ns) ? ns.join(".") : ns,
                key,
              },
            });
            if (process.env.NODE_ENV === "development") {
              console.log("key missing", {
                lng: Array.isArray(lngs) ? lngs.join(".") : lngs,
                ns: Array.isArray(ns) ? ns.join(".") : ns,
                key,
              });
            }
          },
          missingInterpolationHandler: (text: string, value: any) => {
            if (process.env.NODE_ENV === "development") {
              console.log("interpolation missing", {
                text: text,
                value: value,
              });
            }
            return "";
          },
          supportedLngs: supportedLanguages,
          debug: false && process.env.NODE_ENV === "development",
          keySeparator: ".",
          returnNull: true,
          interpolation: {
            escapeValue: true, // needed because custom useTranslation can use $html
            prefix: "{",
            suffix: "}",
          },
        });

      setIsInit(true);
    })();
  }, [appInsights, availableLanguages, defaultLanguage, resources]);

  return <>{isInit && children}</>;
}
