import parse, { domToReact } from "html-react-parser";
import { i18n } from "i18next";
import { omitBy } from "lodash";
import React, { ReactNode, Suspense, useCallback } from "react";
import { useTranslation as useNativeTranslation } from "react-i18next";

import { Check } from "@mui/icons-material";
import { alpha, Avatar, Box } from "@mui/material";

import { CollapsiblePanel } from "../components";
import { useLayout } from "./";

const PassesIcon = React.lazy(() => import("./translations/PassesIcon"));

const options = {
  replace: (domNode: any) => {
    if (domNode.attribs?.id === "neo-passes-icons") {
      return (
        <Suspense>
          <PassesIcon />
        </Suspense>
      );
    }
    if (domNode.type === "tag" && (domNode.name === "ol" || domNode.name === "ul")) {
      return (
        <Box component={domNode.name} sx={{ listStyle: "none", pl: 0 }}>
          {domToReact(domNode.children, options)}
        </Box>
      );
    }
    if (domNode.type === "tag" && domNode.name === "li") {
      return (
        <Box
          component="li"
          sx={{
            listStyle: "none",
            display: "flex",
            alignItems: "center",
            textAlign: "start",
            mb: 2,
          }}
        >
          {domNode.parent.name === "ul" && (
            <Check
              sx={{
                backgroundColor: (t) => alpha(t.palette.primary.main, 0.24),
                p: 1,
                mr: 2,
                borderRadius: 4,
                width: 32,
                height: 32,
              }}
            />
          )}
          {domNode.parent.name === "ol" && (
            <Avatar
              sx={{ mr: 2, width: 24, height: 24, bgcolor: "text.primary", color: "white", fontSize: "0.825rem" }}
            >
              {domNode.parent.children.filter((c: any) => c.name === "li").indexOf(domNode) + 1}
            </Avatar>
          )}
          <Box sx={{ alignItems: "flex-start" }}>{domToReact(domNode.children, options)}</Box>
        </Box>
      );
    }
  },
};

const htmlTransform = (value: string): ReactNode => {
  const match = /^\s*\$html(?<html>.*)$/s.exec(value);

  if (match) {
    const html = match.groups?.["html"].trim();
    if (html) {
      return parse(html, options);
    } else {
      return "";
    }
  } else {
    return value;
  }
};

export const collapsiblePanelTransform = (value: string) => {
  // SEE https://stackoverflow.com/questions/18703187/count-parentheses-with-regular-expression. It avoid issue if content has bracket
  // and https://stackoverflow.com/questions/546433/regular-expression-to-match-balanced-parentheses
  const match =
    /^(?<preContent>.*)?\$collapsible\((?<title>[^)(]*(?:\([^)(]*(?:\([^)(]*(?:\([^)(]*\)[^)(]*)*\)[^)(]*)*\)[^)(]*)*)\)(?<content>.*)$/s.exec(
      value
    );

  if (match) {
    const preContent = match.groups?.["preContent"]?.trim();
    const content = match.groups?.["content"].trim();
    const rawTitle = match.groups?.["title"].trim();
    const title = rawTitle ? htmlTransform(rawTitle) : undefined;

    if (content) {
      return (
        <>
          {preContent && htmlTransform(preContent)}
          <CollapsiblePanel title={title}>{htmlTransform(content)}</CollapsiblePanel>
        </>
      );
    } else {
      return "";
    }
  } else {
    return htmlTransform(value);
  }
};

export default function useTranslation(
  ns?: string | string[],
  { i18n: optionali18n, useSuspense, ...options }: { i18n?: i18n; useSuspense?: boolean } & Record<string, any> = {}
) {
  if (typeof ns === "undefined") {
    ns = ["common"];
  } else if (!Array.isArray(ns)) {
    ns = [ns];
  }
  if (ns.indexOf("common") === -1) {
    ns.push("common");
  }
  const { t, i18n } = useNativeTranslation(
    ns,
    omitBy({ i18n: optionali18n, useSuspense }, (v) => v === undefined)
  );

  const { tenant } = useLayout();

  const translate = useCallback(
    (key: string, data: any = {}) => {
      data = Object.assign(options, data, { tenant });
      let rawValue = t(key, data);

      if (rawValue != null && typeof rawValue !== "undefined" && typeof rawValue !== "string") {
        rawValue = rawValue.toString();
      }
      const value = collapsiblePanelTransform(rawValue);
      return value;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, tenant, ...Object.entries(options).map(([k, v]) => `${k}-${JSON.stringify(v)}`)]
  );
  return { t: translate, i18n };
}
