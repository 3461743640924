import { useCallback, useMemo, useRef } from "react";

export default function useAbortController() {
  const abortControllerRef = useRef<AbortController>();

  const reset = useCallback(() => {
    abortControllerRef.current?.abort();
    if (typeof AbortController === "function") {
      abortControllerRef.current = new AbortController();
    } else {
      // fake abortcontroller for old browser
      abortControllerRef.current = { abort: () => {}, signal: new AbortSignal() };
    }
  }, []);

  const abortControllerMemo = useMemo<AbortController & { reset: () => void }>(() => {
    return {
      get signal() {
        if (!abortControllerRef.current) {
          reset();
        }
        return abortControllerRef.current!.signal;
      },
      abort() {
        return abortControllerRef.current?.abort();
      },
      reset() {
        return reset();
      },
    };
  }, [reset]);

  return abortControllerMemo;
}
