import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, DialogTitle as MuiDialogTitle, DialogTitleProps, IconButton } from "@mui/material";

export default function DialogTitle({
  autoCloseDelay,
  children,
  onClose,
  ...props
}: React.PropsWithChildren<{ autoCloseDelay?: number; onClose?: () => void } & DialogTitleProps>) {
  const [elapsedTime, setElapsedTime] = useState(0);

  if (typeof autoCloseDelay !== "undefined") {
    autoCloseDelay = autoCloseDelay * 1000;
  }

  useEffect(() => {
    if (!autoCloseDelay || autoCloseDelay < 0) {
      return;
    }
    const timer = setInterval(() => {
      setElapsedTime((previousValue) => {
        let newValue = previousValue + 200;

        if (typeof autoCloseDelay === "undefined" || autoCloseDelay < 0) {
          clearInterval(timer);
        } else if (newValue >= autoCloseDelay) {
          newValue = autoCloseDelay;
          clearInterval(timer);
        }
        return newValue;
      });
    }, 200);
    return () => clearInterval(timer);
  }, [autoCloseDelay]);

  if (elapsedTime === autoCloseDelay) {
    onClose?.();
  }

  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        p: 2,
      }}
      typography={{ variant: "h6" }}
      {...props}
    >
      {children}
      {onClose && (
        <>
          {autoCloseDelay && (
            <Box
              sx={(t) => ({
                position: "absolute",
                right: t.spacing(1.5),
                top: t.spacing(1.5),
                color: t.palette.grey[500],
              })}
            >
              <CircularProgress variant="determinate" value={(elapsedTime / autoCloseDelay) * 100} />
            </Box>
          )}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(t) => ({
              position: "absolute",
              right: t.spacing(1),
              top: t.spacing(1),
              color: t.palette.grey[500],
            })}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </>
      )}
    </MuiDialogTitle>
  );
}
