import React from "react";

import { Box, FormHelperText, styled } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

const Span = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, 1),
}));

export default function LabelledOutline({
  id,
  label,
  error,
  helperText,
  children,
}: React.PropsWithChildren<{ id: string; label: React.ReactNode; error?: boolean; helperText?: string }>) {
  return (
    <Box
      sx={{
        position: "relative",
        border: "1px solid",
        borderColor: (t) => (t.palette.mode === "dark" ? "#FFFFFF" : t.palette.grey[300]),
        borderRadius: 1,
      }}
    >
      <InputLabel htmlFor={id} variant="outlined" shrink error={error}>
        <Span>{label}</Span>
      </InputLabel>
      <Box
        id={id}
        sx={{
          mt: "-24px",
          mb: "-6px",
        }}
      >
        {children}
        {error && helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </Box>
    </Box>
  );
}
