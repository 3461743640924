import axios, { AxiosRequestHeaders } from "axios";
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";

type authenticationContextType = {
  token: string | null;
  setToken: (token: string | null) => void;
};
const authenticationContext = createContext<authenticationContextType | null>(null);

export function AuthenticationContextProvider({ children }: { children: ReactNode | undefined }) {
  const tokenRef = useRef<string | null>(null);
  const [, setDummyState] = useState(0); // require to force refresh

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        if (config.url && config.url.startsWith("/api/") && tokenRef.current) {
          if (!config.headers) {
            config.headers = {} as AxiosRequestHeaders;
          }
          config.headers.authorization = `neo-customer ${tokenRef.current}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  const setToken = useCallback((token: string | null) => {
    setDummyState((i) => i + 1);
    tokenRef.current = token;
  }, []);

  return (
    <authenticationContext.Provider value={{ token: tokenRef.current, setToken }}>
      {children}
    </authenticationContext.Provider>
  );
}

export const useAuthentication = () => {
  const context = useContext(authenticationContext);
  if (context == null) {
    throw new Error("AuthenticationContextProvider missing");
  }
  return context;
};
