import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { createTheme, Theme, ThemeProvider as CoreThemeProvider } from "@mui/material";
import { alpha } from "@mui/material/styles";

import useLayout from "./LayoutProvider";

export default function ThemeProvider({
  theme,
  children,
}: React.PropsWithChildren<{ theme?: Partial<Theme> | ((outerTheme: Theme) => Theme) }>) {
  const layout = useLayout();
  const { i18n } = useTranslation();

  const [currentTheme, setCurrentTheme] = useState<Theme>();
  const isRtl = ["ar", "dv", "fa", "he", "ks", "ku", "ps", "ur", "yi"].indexOf(i18n.language.split("-")[0]) >= 0;

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, ...(isRtl ? [rtlPlugin] : [])],
  });

  useEffect(() => {
    const themeOptions = merge(
      {
        palette: {
          background: {
            contrastText: "#FFF9",
          },
        },
        shape: {
          borderRadius: 8,
        },
        typography: {
          fontFamily: [
            "Geneva",
            // https://github.com/necolas/normalize.css/issues/665
            "-apple-system",
            "system-ui",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          h1: {
            fontSize: "3.5rem",
            fontWeight: "700",
          },
          h2: {
            fontSize: "1.9rem",
            fontWeight: "600",
          },
          h3: {
            fontSize: "2.3rem",
            lineHeight: 1.3,
          },
          h5: {
            fontWeight: 700,
          },
          h6: {
            fontSize: "1.25rem",
            lineHeight: 1.4,
            fontWeight: 600,
          },
          subtitle2: {
            fontWeight: "normal",
          },
        },
      },
      layout.theme || {}
    );

    themeOptions.direction = isRtl ? "rtl" : "ltr";
    document.dir = isRtl ? "rtl" : "ltr";
    document.documentElement.lang = i18n.language;

    if (
      themeOptions.palette?.primary &&
      "main" in themeOptions.palette.primary &&
      !("light" in themeOptions.palette.primary)
    ) {
      themeOptions.palette.primary.light = alpha(themeOptions.palette.primary.main, 0.2);
    }

    let initialTheme = createTheme(themeOptions);
    initialTheme = merge(
      {
        components: {
          MuiTextField: {
            defaultProps: {
              variant: "outlined",
            },
          },
          MuiFormControl: {
            defaultProps: {
              variant: "outlined",
            },
          },
          MuiBackdrop: {
            styleOverrides: {
              root: {
                zIndex: initialTheme.zIndex.drawer + 1,
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                zIndex: initialTheme.zIndex.drawer + 2,
              },
            },
          },
        },
      },
      initialTheme
    );

    if (typeof theme === "function") {
      setCurrentTheme(theme(initialTheme));
    } else if (typeof theme !== "undefined") {
      setCurrentTheme(merge(initialTheme, theme));
    } else {
      setCurrentTheme(initialTheme);
    }
  }, [i18n.language, isRtl, layout.theme, theme]);

  return (
    <>
      {currentTheme && (
        <CacheProvider value={cacheRtl}>
          <CoreThemeProvider theme={currentTheme}>{children}</CoreThemeProvider>
        </CacheProvider>
      )}
    </>
  );
}
