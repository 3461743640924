// FROM https://github.com/makannew/use-delayed-state
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";

// Define a generic type T to allow the state to be of any type
export default function useDelayedState<T>(
  initialState: T
): [T, (value: SetStateAction<T>, delay: number) => void, () => void] {
  const [state, setState] = useState<T>(initialState);
  const timeoutRef = useRef<number | null>(null);

  const setStateAfter = useCallback((value: SetStateAction<T>, delay: number | undefined = 0) => {
    if (delay === 0 || delay === undefined) {
      setState(value);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        setState(value);
        timeoutRef.current = null;
      }, delay);
    }
  }, []);

  const cancelSetState = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return [state, setStateAfter, cancelSetState];
}
