import { useContext } from "react";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { AppInsightsContext } from "../contexts";

export default function useAppInsightsContext() {
  const appInsights = useContext<ApplicationInsights | null>(AppInsightsContext);

  if (appInsights === null) {
    throw new Error("AppInsights is not yet initialized");
  }

  return appInsights;
}
