import axios from "axios";
import { useEffect } from "react";

import { useLoader } from "../contexts";

export default function AxiosLoader() {
  const { setIsLoading } = useLoader();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const { loader, ...rest } = config;
        setIsLoading(true, {
          showLinearProgress: true,
          showBackdrop: false,
          ...loader,
        });
        return rest;
      },
      (error) => {
        setIsLoading(false);
        if (typeof error !== "undefined") {
          return Promise.reject(error);
        } else {
          return Promise.resolve();
        }
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setIsLoading(false);
        return response;
      },
      (error) => {
        setIsLoading(false);
        if (typeof error !== "undefined") {
          return Promise.reject(error);
        } else {
          return Promise.resolve();
        }
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [setIsLoading]);

  return null;
}
