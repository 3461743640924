import React from "react";

const AppleLogin = React.lazy(() => import("./AppleLogin"));
const GoogleLogin = React.lazy(() => import("./GoogleLogin"));
const FacebookLogin = React.lazy(() => import("./FacebookLogin"));
const LineLogin = React.lazy(() => import("./LineLogin"));

export const LoginProviders = {
  apple: AppleLogin,
  android: GoogleLogin,
  facebook: FacebookLogin,
  line: LineLogin,
};
