//@ts-nocheck

import { useEffect } from "react";

import { useLayout } from "../hooks";

export default function Clarity() {
  const { clarity } = useLayout();

  useEffect(() => {
    if (clarity?.projectId) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            // eslint-disable-next-line prefer-rest-params
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", clarity?.projectId);
    }
  }, [clarity?.projectId]);

  return null;
}
