import { orderBy } from "lodash";
import { useState } from "react";

import { ArrowDropDown as ArrowDownIcon, Check as CheckIcon, Language as LanguageIcon } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, SxProps, Theme } from "@mui/material";

import { useLayout, useTranslation } from "../hooks";
import IntlLoader from "../runtime/IntlLoader";
import { getLanguages } from "../runtime/tools/language";
import mergeSx from "../runtime/tools/mergeSx";

export interface LanguageSelectorProps {
  sx?: SxProps<Theme>;
}

const LanguageSelector = ({ sx }: LanguageSelectorProps) => {
  const { i18n } = useTranslation("common.languageSelector");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const layout = useLayout();

  const { defaultLanguage = "en", availableLanguages = [] } = layout.internationalization;
  if (availableLanguages.length === 0) {
    availableLanguages.push(defaultLanguage);
  }
  const languages = getLanguages(availableLanguages);

  if (availableLanguages.length <= 1) {
    return null;
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = async (lng: string) => {
    setAnchorEl(null);
    await i18n.changeLanguage(lng);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        endIcon={<ArrowDownIcon />}
        variant="outlined"
        sx={mergeSx(
          {
            color: "text.primary",
            borderRadius: 4,
            borderColor: "grey.300", // TODO: review grey palette ?
            textTransform: "none",
          },
          sx
        )}
      >
        <LanguageIcon sx={{ mx: 0.5, mb: 0.2, fontSize: "1rem", verticalAlign: "middle" }} />
        {languages.find((l) => l.lng === i18n.language)?.name || i18n.language}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {orderBy(languages, (o) => o.name).map((l) => (
          <MenuItem onClick={(e) => handleClick(l.lng)} key={l.lng}>
            <ListItemIcon style={{ minWidth: "20px" }}>{i18n.language === l.lng && <CheckIcon />}</ListItemIcon>
            <ListItemText>{l.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default function LS(props: LanguageSelectorProps) {
  return (
    <IntlLoader>
      <LanguageSelector {...props} />
    </IntlLoader>
  );
}
