import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";

import { alpha, Backdrop, Box, CircularProgress, LinearProgress } from "@mui/material";

import { LoaderConfig } from "../../definitions/LoaderConfig";
import { useDelayedState } from "../hooks";

const LoaderContext = createContext<{
  readonly isLoading: boolean;
  setIsLoading: (value: boolean, options?: LoaderConfig) => void;
} | null>(null);

const useLoaderContext = () => {
  const context = useContext(LoaderContext);
  if (context == null) {
    throw new Error("Loader context is null");
  }
  return context;
};

function Loader({ showBackdrop = false, showLinearProgress = true, backdropChildren }: LoaderConfig) {
  const { isLoading } = useLoaderContext();

  return (
    <>
      {isLoading && (
        <>
          {!showBackdrop && showLinearProgress && (
            <LinearProgress sx={(t) => ({ position: "fixed", width: "100%", zIndex: t.zIndex.modal + 2 })} />
          )}
          {showBackdrop && (
            <>
              <Backdrop
                sx={(t) => ({
                  zIndex: t.zIndex.modal + 1,
                  backgroundColor: alpha(t.palette.background.default, 0.7),
                })}
                open
              >
                {backdropChildren || <CircularProgress color="primary" />}
              </Backdrop>
            </>
          )}
        </>
      )}
    </>
  );
}

function LoaderContextProvider({ children }: PropsWithChildren<unknown>) {
  const [loaderDepth, setLoaderDepth] = useState(0);
  const [loaderOptions, setLoaderOptions] = useState<LoaderConfig>();
  const [isLoading, setIsLoadingInternal, cancelSetIsLoadingInternal] = useDelayedState(false);

  useEffect(() => {
    const isLoading = loaderDepth > 0;
    cancelSetIsLoadingInternal();
    setIsLoadingInternal(isLoading, isLoading ? 0 : 500);
  }, [loaderDepth, setIsLoadingInternal, cancelSetIsLoadingInternal]);

  const setIsLoading = useCallback((isLoading: boolean, options?: LoaderConfig) => {
    setLoaderDepth((previousValue) => {
      if (previousValue === 0) {
        setLoaderOptions(options);
      }
      const newValue = isLoading ? previousValue + 1 : previousValue - 1;
      return newValue;
    });
  }, []);

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Loader
          showBackdrop={loaderOptions?.showBackdrop}
          showLinearProgress={loaderOptions?.showLinearProgress}
          backdropChildren={loaderOptions?.backdropChildren}
        />
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            ...(isLoading && loaderOptions?.showBackdrop
              ? {
                  filter: "blur(10px)",
                }
              : {}),
          }}
        >
          {children}
        </Box>
      </Box>
    </LoaderContext.Provider>
  );
}

function useLoader() {
  const ctx = useLoaderContext();
  const { isLoading, setIsLoading } = ctx;

  const withLoader = useCallback(
    async <T extends unknown>(fnc: () => Promise<T> | T, config?: LoaderConfig) => {
      setIsLoading(true, config);
      try {
        const result = await fnc();
        return result;
      } finally {
        setIsLoading(false, config);
      }
    },
    [setIsLoading]
  );

  return {
    isLoading,
    withLoader,
    setIsLoading,
  };
}

export { LoaderContextProvider, useLoader };
