import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useTheme } from "@mui/material";

import { useDocument, useLayout } from "../hooks";

const addHeader = function (tagName: string, attributes: { [key: string]: string }) {
  const node = document.createElement(tagName);
  for (const k in attributes) {
    node.setAttribute(k, attributes[k]);
  }
  document.head.appendChild(node);
};

export default function Manifest() {
  const layout = useLayout();

  const theme = useTheme();
  const [urlSearchParams] = useSearchParams();

  const location = document.location;

  urlSearchParams.delete("neo.pwaInstall");
  urlSearchParams.set("neo.pwa", "true");

  const appTitle = layout.appTitle || layout.tenant.name;

  const favicon = useDocument(layout.faviconUrl);
  const largeFavicon = useDocument(layout.largeFaviconUrl || null);
  const appleFavicon = useDocument(layout.appleFaviconUrl || null);

  if (favicon == null) {
    throw new Error(`faviconUrl is missing : '${layout.faviconUrl}'`);
  }

  const manifest = {
    name: appTitle,
    short_name: layout.tenant.name,
    start_url: `${location.origin}${location.pathname}?${urlSearchParams.toString()}`,
    background_color: theme.palette.background.default,
    theme_color: theme.palette.primary.main,
    display: "standalone",
    scope: location.origin,
    icons: [
      {
        src: location.origin + (largeFavicon?.url || favicon.url),
        sizes: "192x192",
        type: "image/png",
      },
    ],
  };
  const stringManifest = JSON.stringify(manifest);

  useEffect(() => {
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    const manifestElement = document.getElementById("manifest") as HTMLLinkElement;
    if (manifestElement) {
      manifestElement.href = manifestURL;
    }
  }, [stringManifest]);

  useEffect(() => {
    const faviconElement = document.getElementById("favicon") as HTMLLinkElement;
    if (faviconElement) {
      faviconElement.href = favicon.url;
    }
    const appleTouchIconUrl = appleFavicon?.url || favicon.url;
    if (appleTouchIconUrl) {
      addHeader("link", { rel: "apple-touch-icon", href: appleTouchIconUrl });
    }
  }, [appleFavicon?.url, favicon.url]);

  useEffect(() => {
    addHeader("meta", { name: "theme-color", content: theme.palette.primary.main });
  }, [theme.palette.primary.main]);

  useEffect(() => {
    document.title = appTitle;
  }, [appTitle]);

  return null;
}
