import { ModuleType } from "i18next";
import { merge } from "lodash";

import { getResource } from "./getResource";

export class StaticBackend {
  type: ModuleType;
  services: any;
  options: any;
  static type: ModuleType;

  constructor(services: any, backendOptions = {}) {
    this.init(services, backendOptions);

    this.type = "backend";
  }

  init(services: any, backendOptions = {}, i18nextOptions = {}) {
    this.services = services;
    this.options = { ...this.options, ...backendOptions };
  }

  read(language: string, ns: string, callback: (error: any, result: string | false) => void) {
    const [area, ...nsParts] = ns.split(".");
    ns = nsParts.join(".");

    let layoutResource = this.options.layoutResources?.[language]?.[area];

    getResource(area, language)
      .then((values) => {
        if (ns) {
          values = values[ns];
          layoutResource = layoutResource?.[ns];
        }
        values = merge(values || {}, layoutResource || {});
        callback(null, values);
      })
      .catch((e) => {
        callback(e, false);
      });
  }
}
StaticBackend.type = "backend";
