import axios from "axios";
import { merge } from "lodash";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";

import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { useAppInsightsContext, useLayout, useTranslation } from "../hooks";

const StoreAdditionalResourcesContext = createContext<{
  readonly isLoaded: boolean;
  setStoreId: (storeId: string | undefined) => void;
} | null>(null);

const useStoreAdditionalResources = () => {
  const context = useContext(StoreAdditionalResourcesContext);
  if (context == null) {
    throw new Error("StoreAdditionalResources context is null");
  }
  return context;
};

function StoreAdditionalResourcesContextProvider({ children }: PropsWithChildren<unknown>) {
  const [storeId, setStoreId] = useState<string>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { appInsights } = useAppInsightsContext();
  const { i18n } = useTranslation();
  const initialResources = useMemo(() => (i18n.options.backend as any)?.layoutResources || {}, [i18n]);
  const {
    tenant: { name: tenantId },
    internationalization,
  } = useLayout();

  const { hasStoreAdditionalResources } = (internationalization || {}) as { hasStoreAdditionalResources?: boolean };

  useEffect(() => {
    if (!hasStoreAdditionalResources) {
      setIsLoaded(true);
      return;
    }
    if (storeId) {
      (async () => {
        const response = await axios.get<Record<string, any>>(
          `/api/${tenantId}/stores/${storeId}/additionalResources`,
          {
            validateStatus: function (status) {
              return status < 400 || status === 401 || status === 404;
            },
            loader: { showBackdrop: false, showLinearProgress: false },
          }
        );
        if (response.status === 401) {
          const message = `Additonal resources has been requested for store ${storeId} (${tenantId}) but store API has not been enabled (401)`;
          console.warn(message);
          appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Warning,
            properties: {
              storeId: storeId,
              tenantId: tenantId,
            },
          });
          return;
        } else if (response.status === 404) {
          const message = `Additional resources has been requested for store ${storeId} (${tenantId}) but store has not been configured (404)`;
          console.warn(message);
          appInsights.trackTrace({
            message: message,
            severityLevel: SeverityLevel.Warning,
            properties: {
              storeId: storeId,
              tenantId: tenantId,
            },
          });
          return;
        }

        const additionalResources = response.data;
        if (additionalResources) {
          const mergedResources = merge({ ...initialResources }, additionalResources);
          (i18n.options.backend as any).layoutResources = mergedResources;

          await i18n.reloadResources();
        }
        setIsLoaded(true);
      })();
    }
  }, [storeId, tenantId, i18n, initialResources, appInsights, hasStoreAdditionalResources]);

  return (
    <StoreAdditionalResourcesContext.Provider
      value={{
        get isLoaded() {
          return isLoaded;
        },
        setStoreId: setStoreId,
      }}
    >
      {children}
    </StoreAdditionalResourcesContext.Provider>
  );
}

export { StoreAdditionalResourcesContextProvider, useStoreAdditionalResources };
