import { ReactNode, useState } from "react";

import { Add as ArrowDownIcon, Remove as ArrowUpIcon } from "@mui/icons-material";
import { Box, BoxProps, Collapse } from "@mui/material";

import { useTranslation } from "../hooks";

export default function CollapsiblePanel({
  title,
  open = false,
  children,
  ...props
}: { title?: ReactNode; open?: boolean } & Omit<BoxProps, "title">) {
  const [openState, setOpenState] = useState(open);
  const { t } = useTranslation("common.collapsiblePanel");

  return (
    <Box {...props}>
      <Box
        onClick={(e) => setOpenState(!openState)}
        sx={{
          cursor: "pointer",
          display: "inline-block",
          "& svg": { fontSize: "1.2rem", verticalAlign: "text-bottom", mx: 0.5 },
        }}
      >
        {title || t("title")}
        {openState ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Box>
      <Collapse in={openState} sx={{ mt: 1, color: "text.secondary" }}>
        {children}
      </Collapse>
    </Box>
  );
}
