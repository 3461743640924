import { useEffect } from "react";

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } // @ts-ignore
  else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  }
  // @ts-ignore
  else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  } else {
    return "visibilitychange";
  }
}
export default function useVisibilityChange(onVisibilityChange: EventListenerOrEventListenerObject) {
  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  }, [onVisibilityChange]);
}
